import { Dispatch, useEffect, useLayoutEffect, useState } from "react"

import { findSearchRedirect, findSelectRedirect } from "./helpers"
import { usePlaid } from "../../../Helpers/Plaid"

import type { AggregatorDataType } from "./types"
import type { PlaidDataActionType } from "../../../Helpers/Reducers/PlaidData/types"
import { postMessageSender } from "../../../Helpers/PostMessage"
import "./style.css"

type PlaidProps = {
  aggregationData: AggregatorDataType
  clientName: string
  env: string
  publicKey: string
  onEvent: Dispatch<PlaidDataActionType>
  onRedirect: (flinksInstitutionId: string, searchInstitutionName?: string) => void
  onSelect: () => void
  onSuccess: (data: {
    linkSessionId: string,
    institutionId: string,
    publicToken: string,
  }) => void
  onExit: () => void
  open: boolean
}

export function Plaid(props: PlaidProps) {
  const { lastPlaidEvent, plaidOpen, plaidReady } = usePlaid(
    props.clientName,
    props.env,
    props.publicKey
  )
  const [isInit, setIsInit] = useState(true)

  useEffect(() => {
    document.body?.style.setProperty("overflow", "auto", "important")

    if (lastPlaidEvent) {
      // removes plaid overflow:hidden that it forcibly sets to body

      props.onEvent({
        type: "ADD_EVENT",
        payload: { ...lastPlaidEvent },
      })

      switch (lastPlaidEvent.event.eventName) {
        // we hide or show scroll overlay for intro screen
        case "TRANSITION_VIEW":
          setIsInit(lastPlaidEvent.event.viewName === "CONSENT")
          break
        case "SEARCH_INSTITUTION":
          findSearchRedirect(
            props.aggregationData.plaidSearchFlinksOverrides,
            lastPlaidEvent.raw?.institution_search_query ?? null,
            props.onRedirect
          )
          break
        case "SELECT_INSTITUTION":
          postMessageSender({
            event: "marketing",
            payload: {
              status: "track",
              data: {
                event: "app_banking_bank_selected",
                properties: {
                  bankInstitution: lastPlaidEvent.raw?.institution_name ?? null,
                },
              },
            },
          })
          findSelectRedirect(
            props.aggregationData.plaidSelectFlinksOverrides,
            lastPlaidEvent.raw?.institution_id ?? null,
            props.onRedirect,
            props.onSelect
          )
          break
        case "EXIT":
          props.onExit()
          break
        case "SUCCESS":
          props.onSuccess({
            linkSessionId: lastPlaidEvent.raw?.link_session_id ?? "",
            institutionId: lastPlaidEvent.raw?.institution?.institution_id ?? "",
            publicToken: lastPlaidEvent.raw?.public_token ?? "",
          })
          break
        default:
          break
      }
    }
  }, [lastPlaidEvent])

  // init iframe
  useLayoutEffect(() => {
    if (!plaidReady) return

    plaidOpen()

    let plaidLinkRoot = document.getElementById("root")
    let plaidContainer = document.getElementById("plaid-container")
    let plaidIframe = document.getElementsByTagName("iframe")[0]
    plaidContainer?.appendChild(plaidIframe)

    if (plaidLinkRoot) {
      plaidLinkRoot.style.setProperty("display", "block", "important")
      // When application come from mobile, there is a display: none !important set by react-plaid-link. We reset to display: block.
    }
  }, [plaidReady])

  // external opener
  useEffect(() => {
    if (props.open) plaidOpen()
    setTimeout(() => plaidOpen(), 200) // sometimes doesn't trigger, so we force reload
  }, [props.open])

  return <div id="plaid-container" className="h-[650px] sm:h-auto" />
}
