import { useEffect, useRef, useState } from "react"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { Loader } from "../Utilities/Loader"
import { Layout } from "../Utilities/Layout"
import { goToMobileVerification, goToRouteStep } from "../../Helpers/Routes"

import {
  LoanParameters,
  MobileVerification,
  PinVerification,
  PersonalDetails,
  ResidentialDetails,
  EmploymentDetails,
  IncomeDetails,
  ReferenceDetails,
  FinancialDetails,
  BankingDetails,
  LoanPreapproved,
} from "../Steps"

import { InstantLoanContext, useInstantLoanReducer } from "../../Helpers/Reducers/InstantLoan"
import { RequestsContext, useRequestsReducer } from "../../Helpers/Reducers/Requests"
import { UserContext, useUserReducer } from "../../Helpers/Reducers/User"
import { ReviewDetails } from "../FastTrackSteps"
import { RepaymentOptions } from "../SidePanels/RepaymentOptions"
import {
  getUserReloadToken,
  getLoanStatus,
  getUser,
  getLoan,
  getGeneralProvinces,
  getStatusFastTrack,
  getMarketingConsent,
} from "../../Helpers/Requests"
import { findProvinceCodeById } from "../../Helpers/Parsers/Steps/LoanParameters/loanParameters"
import { postMessageSender } from "../../Helpers/PostMessage"
import { useStackDriver } from "../../Helpers/StackDriver"
import { LoanConnectContentType } from "../../Helpers/Cookies/loan-connect"
import { useLogRocket } from "../../Helpers/LogRocket"
import { getAggregators } from "../Steps/BankingDetails/helpers"

import Image2 from '../../images/support.png'
import Image3 from '../../images/symbol-instant-loans-half-left.svg'

const preloadSrcList: string[] = [
  Image2,
  Image3
]

function preloadImage (src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}
function App() {
  const navigate = useNavigate()
  const [loanState, loanDispatch] = useInstantLoanReducer()
  const [requestsState, requestsDispatch] = useRequestsReducer()
  const [userState, userDispatch] = useUserReducer()
  const [isLoading, setLoading] = useState<boolean>(true)
  const { updateUser } = useStackDriver()
  const marketingReferralContent = useRef<LoanConnectContentType | null>(null)
  const logRocket = useLogRocket()

  const { pathname, search } = useLocation()
  const path = pathname.substring(1)

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const isApp = searchParams.get("isApp")
    if (isApp && isApp === "true") {
      loanDispatch({ step: "isApp", payload: { isApp: true } })
    }
  }, [search])

  // load token & write into state
  useEffect(() => {
    logRocket.init()

    const authEventListener = (e: MessageEvent) => {
      let message:any|undefined = undefined
      try {
        message = typeof e.data === "string" ? JSON.parse(e.data) : e.data
      } catch (error) {
        console.warn(error);
        return;
      }
      if (message.marketingReferralContent) {
        marketingReferralContent.current = message.marketingReferralContent
      }
      if (message.token) {
        requestsDispatch({
          type: "setToken",
          payload: { token: message.token },
        })
      }
      if (message.route) {
        navigate(message.route)
      }
    }
    window.addEventListener("message", authEventListener)

    return () => {
      window.removeEventListener("message", authEventListener)
    }
  }, [])

  const onGetUserReloadToken = async () => {
    try {
      setLoading(true)
      const response = await getUserReloadToken(requestsState.token)
    } catch (error) {
      console.warn(error)
    }
  }

  // Here we reset the token every time minutes
  useEffect(() => {
    if (requestsState.token !== undefined && requestsState.token !== "") {
      setInterval(async () => onGetUserReloadToken, 600000) // 10 minutes = 600000
    }
  }, [requestsState])

  // check if application exists before navigating
  useEffect(() => {
    if (!requestsState.token) return

    const imagesPromiseList: Promise<any>[] = []
    for (const i of preloadSrcList) {
      imagesPromiseList.push(preloadImage(i))
    }

    Promise.all([
      getGeneralProvinces(),
      getLoanStatus(requestsState.token),
      getUser(requestsState.token),
      getLoan(requestsState.token),
      getStatusFastTrack(requestsState.token),
      getAggregators(requestsState.token),
      getMarketingConsent(requestsState.token),
      ...imagesPromiseList
    ])
      .then(([provinces, loan, user, loanInfo, fastTrackInfo, aggregationData, marketingConsentData]) => {

        if (aggregationData.error) {
          console.error(aggregationData.error);
          throw new Error("Aggregation data not found.")
        }

        loanDispatch({
          step: "aggregator",
          payload: {
            aggregator: aggregationData.result
          },
        })

        if (marketingConsentData.data) {
          loanDispatch({
            step: "marketingConsent",
            payload: {
              marketingConsent: marketingConsentData.data.subscribedAll
            },
          })
        }

        if (provinces.result) {
          const provincesData = provinces.result
          loanDispatch({
            step: "provinces",
            payload: provincesData,
          })
        } else {
          postMessageSender({
            event: "error",
            error: {
              message: "Cannot fetch provinces info",
              data: {},
              code: 400,
            },
          })
        }

        // provide user into context
        userDispatch({
          type: "setUser",
          payload: { user: user.result },
        })

        if (user.result.email) {
          updateUser(user.result.email)
        }

        // LoanConnect integration -> setting phone number with cookie marketingReferralContent
        if (marketingReferralContent.current) {
          loanDispatch({
            step: "mobileVerification",
            payload: {
              mobileNumber: marketingReferralContent.current.phone,
            },
          })
        }

        const provinceCode = findProvinceCodeById(user.result.provinceId)

        if (loanInfo.amount != null && loanInfo.amount !== 0) {
          loanDispatch({
            step: "loanParameters",
            payload: { ...loanInfo, province: provinceCode },
          })
        } else {
          // LoanConnect integration -> setting amount with cookie marketingReferralContent when nothing is set.
          if (marketingReferralContent.current) {
            let amount = Number(marketingReferralContent.current.amount)
            if (isNaN(amount) || amount > 1500) {
              amount = 500
            }
            loanDispatch({
              step: "loanParameters",
              payload: { ...loanState.loanParameters, amount: amount, province: provinceCode },
            })
          } else {
            loanDispatch({
              step: "loanParameters",
              payload: { ...loanState.loanParameters, province: provinceCode },
            })
          }
        }

        setLoading(false)
        if (loan?.status === "canApply") return

        loanDispatch({
          step: "loading",
          payload: { loading: false },
        })

        // load navigation state if app status exists
        if (
          fastTrackInfo?.data?.isFastTrackAvailable &&
          (fastTrackInfo?.data?.fastTrackWanted ?? true) &&
          fastTrackInfo?.data?.fastTrackStep === 1 &&
          user?.result?.applicationStep === 6
        ) {
          if (user.result.shouldValidatePhone) {
            goToMobileVerification(navigate);
          } else {
            return navigate("/fast-track/review", { state: { title: "Review and complete" } })
          }
        } else if (user.result.applicationStep != null && user.result.applicationStep !== 0) {
          if (user.result.shouldValidatePhone) {
            goToMobileVerification(navigate);
          } else {
            goToRouteStep(
              navigate,
              user.result.applicationStep === 7 || user.result.applicationStep === 6
                ? user.result.applicationStep === 6 ? 5 : user.result.applicationStep ///We removed the step 6 (reference details view so if step 6 then we go to financial step (5))
                : user.result.applicationStep + 1
            )
          }
        }

      })
      .catch((e) => {
        console.error('app error:', e);
      })
  }, [requestsState.token])

  useEffect(() => {
    postMessageSender({ event: "ready" })
  }, [])

  return (
    <UserContext.Provider value={{ state: userState, dispatch: userDispatch }}>
      <InstantLoanContext.Provider value={{ state: loanState, dispatch: loanDispatch }}>
        <RequestsContext.Provider value={{ state: requestsState, dispatch: requestsDispatch }}>
          <Loader loading={isLoading} extraPadding>
            <Routes>
              <Route path="repayment-options" element={<RepaymentOptions />} />
              <Route path="" element={<Layout />}>
                <Route path="mobile-verification/*" element={<MobileVerification />} />
                <Route path="pin-verification/*" element={<PinVerification />} />
                <Route path="personal-details/*" element={<PersonalDetails />} />
                <Route path="residential-details/*" element={<ResidentialDetails />} />
                <Route path="employment-details/*" element={<EmploymentDetails />} />
                <Route path="income-details/*" element={<IncomeDetails />} />
                <Route path="reference-details/*" element={<ReferenceDetails />} />
                <Route path="financial-details/*" element={<FinancialDetails />} />
                <Route path="banking-details/*" element={<BankingDetails />} />
                <Route path="loan-preapproved" element={<LoanPreapproved />} />
                <Route path="fast-track/review" element={<ReviewDetails />} />
                <Route index element={<LoanParameters />} />
              </Route>
            </Routes>
          </Loader>
        </RequestsContext.Provider>
      </InstantLoanContext.Provider>
    </UserContext.Provider>
  )
}

export default App
