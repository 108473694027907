import { InstantLoanStateType } from "../../../Reducers/types"
import { Validators } from "../../types"

export const personalDetailsValidators: Validators<InstantLoanStateType["personalDetails"]> = {
  firstName(value) {
    if (!value) return "Required"
    if (value.length < 1) return "Must be more than 1 characters"
    if (value.length >= 75) return "Must be less than 75 characters"
    if (/[^a-zA-Z'-\s]/.test(value)) return "Only latin letters, single quote or dash"
  },
  middleName(value) {
    if (!value) return
    if (value.length >= 75) return "Must be less than 75 characters"
    if (/[^a-zA-Z'-\s]/.test(value)) return "Only latin letters, single quote or dash"
  },
  lastName(value) {
    if (!value) return "Required"
    if (value.length < 1) return "Must be more than 1 characters"
    if (value.length >= 75) return "Must be less than 75 characters"
    if (/[^a-zA-Z'-\s]/.test(value)) return "Only latin letters, single quote or dash"
  },
  gender(value) {
    if (!value) return "Required"
  },
  sin(value) {
    value = Number(value)
    if (Number.isNaN(value)) return "Required"
    if (value === 0) return
    if (value.toString().length !== 9) return "Must be 9 digits"
  },
  dateOfBirth(value) {
    if (!value) return "Required"
  },
  maritalStatus(value) {
    if (!value) return "Required"
  },
  numberOfDependants(value) {
    if (!value) return "Required"
  },
  levelOfEducation(value) {
    if (!value) return "Required"
  },
  homePhone(value: string, values) {
    if (values.otherPhone !== undefined) return
    if (value.length < 10) return "Enter a valid 10 digit phone number"
    if (value.includes("+")) return "Do not include the country code (eg. +1)"
    if (!value) return "Enter a valid 10 digit phone number"
  },
  otherPhone(value: string, values) {
    if (values.homePhone !== undefined) return
    if (value.length < 10) return "Enter a valid 10 digit phone number"
    if (value.includes("+")) return "Do not include the country code (eg. +1)"
    if (!value) return "Enter a valid 10 digit phone number"
  },
  otherRelationship(value, values) {
    if (values.homePhone !== undefined) return
    if (!value.length) return "Required"
  },
  otherFirstName(value, values) {
    if (values.homePhone !== undefined) return
    if (value.length <= 1) return "Must be more than 1 characters"
    if (value.length >= 75) return "Must be less than 75 characters"
    if (/[^a-zA-Z'-]/.test(value)) return "Only latin letters, single quote or dash"
  },
  otherLastName(value, values) {
    if (values.homePhone !== undefined) return
    if (value.length <= 1) return "Must be more than 1 characters"
    if (value.length >= 75) return "Must be less than 75 characters"
    if (/[^a-zA-Z'-]/.test(value)) return "Only latin letters, single quote or dash"
  },
}
