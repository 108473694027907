import { useReducer } from "react"
import type { PlaidDataStateType, PlaidDataActionType } from "./types"

const initialPlaidData: PlaidDataStateType = { events: [] }

const plaidDataReducer = (
  state: PlaidDataStateType,
  action: PlaidDataActionType
): PlaidDataStateType => {
  switch (action.type) {
    case "ADD_EVENT":
      switch (action.payload.event.eventName) {
        case "SUCCESS":
          return {
            ...state,
            events: [...state.events, action.payload.event],
            linkSessionId: action.payload.raw?.link_session_id ?? "",
            institutionId: action.payload.raw?.institution?.institution_id ?? "",
            publicToken: action.payload.raw?.public_token ?? "",
          }
        default:
          return { ...state, events: [...state.events, action.payload.event] }
      }
    case "ADD_LINK_SESSION_ID":
      return { ...state, linkSessionId: action.payload }
    case "ADD_INSTITUTION_ID":
      return { ...state, institutionId: action.payload }
    case "ADD_PUBLIC_TOKEN":
      return { ...state, publicToken: action.payload }
    default:
      return { ...state }
  }
}

export const usePlaidDataReducer = () => useReducer(plaidDataReducer, initialPlaidData)
