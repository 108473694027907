import { useState, useEffect, useContext, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import { Popup } from "../../Utilities/Popup"
import { Plaid } from "../../Utilities/Plaid"
import { Flinks } from "../../Utilities/Flinks"
import { Button, SummaryBox } from "@brainfinance/icash-component-library"
import SupportImage from "../../../images/support.png"
import {
  getAggregators,
  postFlinksDecision,
  postPlaidAssetReport,
  postPlaidEvents,
} from "./helpers"
import { usePlaidDataReducer } from "../../../Helpers/Reducers/PlaidData"
import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { RequestsContext } from "../../../Helpers/Reducers/Requests"

import { goToNext, goToPrevious } from "../../../Helpers/Routes"
import { UserContext } from "../../../Helpers/Reducers/User"
import { FlinksDataStateType } from "../../../Helpers/Reducers/FlinksData/types"
import { postMessageSender } from "../../../Helpers/PostMessage"
import { PlaidDataActionType } from "../../../Helpers/Reducers/PlaidData/types"
import { toast } from "react-toastify"
import { postMarketingGroupSubscribe } from "../../../Helpers/Requests"

const config = {
  plaid: {
    publicKey: String(process.env.REACT_APP_PLAID_KEY),
    clientName: String(process.env.REACT_APP_PLAID_CLIENT_NAME),
    env: String(process.env.REACT_APP_PLAID_ENV),
  },
  flinks: {
    flinksIframeURL: String(process.env.REACT_APP_FLINKS_URL),
  },
}

export function BankingDetails() {
  const [flinksInstitutionId, setFlinksInstitutionId] = useState<string | undefined>()
  const [searchInstitutionName, setSearchInstitutionName] = useState<string | undefined>()
  const [service, setService] = useState<"plaid" | "flinks">(process.env.REACT_APP_BANKING_SERVICE ?? "plaid")
  const [handleSuccess, setHandleSuccess] = useState<(plaid: typeof plaidData) => void>()
  const plaidSuccessRef = useRef(false)

  const {
    state: { aggregator, marketingConsentUpdated },
  } = useContext(InstantLoanContext)

  // in mobiles we open plaid in own view
  const [openMobileBankingDialog, setOpenMobileBankingDialog] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [plaidData, plaidDispatch] = usePlaidDataReducer()


  const onPlaidEvent = async (data: PlaidDataActionType) => {
    if (data.type === 'ADD_EVENT') {
      const event = {
        errorCode: data.payload.raw.error_code,
        errorMessage: data.payload.raw.error_message,
        eventName: data.payload.event.eventName,
        exitStatus: data.payload.raw.exit_status,
        institutionId: data.payload.raw.institution_id,
        institutionName: data.payload.raw.institution_name,
        institutionSearchQuery: data.payload.raw.institution_search_query,
        linkSessionId: data.payload.event.linkSessionId,
        requestId: data.payload.raw.request_id,
        timestamp: data.payload.event.timestamp,
        viewName: data.payload.event.viewName
      }
      if (data.payload?.event?.linkSessionId !== undefined && data.payload?.event?.linkSessionId !== '') {
        await postPlaidEvents(token ?? "", {
          linkSessionId: data.payload.event.linkSessionId,
          events: [event]
        });  
      }
    }
    plaidDispatch(data);
  }

  const onFlinksEvent = async (data: FlinksDataStateType) => {
    return await postFlinksDecision(token ?? "", data);
  }

  const {
    state: { user },
  } = useContext(UserContext)

  // we need to run success event in the next render when plaidData is ready
  useEffect(() => {
    handleSuccess?.(plaidData)
  }, [plaidData])

  const {
    state: { loanParameters },
  } = useContext(InstantLoanContext)
  const {
    state: { token },
  } = useContext(RequestsContext)

  const handlePlaidRedirect = (flinksInstitutionId: string, searchInstitutionName?: string) => {
    setFlinksInstitutionId(flinksInstitutionId)
    setSearchInstitutionName(searchInstitutionName)
  }

  const handleFlinksRedirect = () => {
    setFlinksInstitutionId(undefined)
    setService("plaid")
  }

  const handlePlaidSelect = () => {
  }

  const handleFlinksSelect = () => {
  }

  const handleExit = () => {
    if (openMobileBankingDialog) setOpenMobileBankingDialog(false)
    else goToPrevious(navigate, pathname.slice(1))
  }

  const handleFlinksSuccess = async (flinksData: FlinksDataStateType) => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_banking_flinks_completed",
        },
      },
    })

    if (marketingConsentUpdated) {
      try {
        await postMarketingGroupSubscribe(token ?? "");
        postMessageSender({
          event: 'marketing-group-updated',
          payload: {
            checked: true
          }
        })
      } catch (error) {}
    }

    goToNext(navigate, pathname.slice(1))
  }

  const handlePlaidSuccess = async (data: {
    linkSessionId: string,
    institutionId: string,
    publicToken: string,
  }) => {
    if (plaidSuccessRef.current) {
      return
    }
    plaidSuccessRef.current = true

    if (data.institutionId === "" || data.linkSessionId === "" || data.publicToken === "") {
      toast("An unknown error occurred, please retry.", { type: "error" })
      plaidSuccessRef.current = false
      if (openMobileBankingDialog) setOpenMobileBankingDialog(false)
      return
    }

    const successAssetReport = await postPlaidAssetReport(token ?? "", data)
    if (!successAssetReport) {
      plaidSuccessRef.current = false
      if (openMobileBankingDialog) setOpenMobileBankingDialog(false)
      return
    }

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_banking_plaid_completed",
        },
      },
    })

    if (marketingConsentUpdated) {
      try {
        await postMarketingGroupSubscribe(token ?? "");
        postMessageSender({
          event: 'marketing-group-updated',
          payload: {
            checked: true
          }
        })
      } catch (error) {}
    }

    goToNext(navigate, pathname.slice(1))
  }

  const handlePopupCancel = () => {
    setSearchInstitutionName(undefined)
    setFlinksInstitutionId(undefined)
  }

  const handlePopupConfirm = () => {
    setService("flinks")
  }

  useEffect(() => {
    
    window.scrollTo(0, 0)
    return () => {
      document.body?.style.setProperty("overflow", "auto", "important")
    }
  }, [])
  
  // scroll to top when we open mobile banking
  useEffect(() => {
    document.getElementById('scroll-anchor')?.scrollIntoView({block:'start'})
  }, [openMobileBankingDialog])

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_banking_loaded",
        },
      },
    })
  }, [])

  useEffect(() => {
    if (service === "flinks") {
      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_banking_flinks_loaded",
          },
        },
      })
    } else if (service === "plaid") {
      postMessageSender({
        event: "marketing",
        payload: {
          status: "track",
          data: {
            event: "app_banking_plaid_loaded",
          },
        },
      })
    }
  }, [service])

  if (!aggregator) {
    return null;
  }

  return (
    <div className="relative max-w-[622px] text-center sm:w-full sm:mx-auto">
      <div className={`text-left mb-[22px] ${openMobileBankingDialog ? "hidden" : ""}`}>
        <h2 className="subtitle-light mb-[16px] hidden sm:block">
          Where should we send your ${loanParameters.amount}?
        </h2>
        <div className="caption-light">
          iCash uses an online banking validation tool to securely verify your identity through your
          financial institution.
          <br />
          This required, one-time step simply allows us to confirm your account details where you
          receive your income.
        </div>
      </div>
      <div className={`${openMobileBankingDialog ? "" : " sm:hidden"} relative`}>
        {service === "flinks" ? (
          <Flinks
            onStep={onFlinksEvent}
            src={config.flinks.flinksIframeURL}
            institutionId={flinksInstitutionId}
            clientEmail={user?.email ?? ""}
            clientId={user?.contactId ?? 0}
            onRedirect={handleFlinksRedirect}
            onSelect={handleFlinksSelect}
            onSuccess={handleFlinksSuccess}
            onExit={handleExit}
            open={openMobileBankingDialog}
          />
        ) : (
          <Plaid
            aggregationData={aggregator}
            onEvent={onPlaidEvent}
            onRedirect={handlePlaidRedirect}
            onSelect={handlePlaidSelect}
            onSuccess={handlePlaidSuccess}
            onExit={handleExit}
            clientName={config.plaid.clientName}
            env={config.plaid.env}
            publicKey={config.plaid.publicKey}
            open={openMobileBankingDialog}
          />
        )}
        {flinksInstitutionId && service === "plaid" && (
          <Popup
            onCancel={handlePopupCancel}
            onConfirm={handlePopupConfirm}
            onClose={handlePopupCancel}
            classNamesContainer="!absolute sm:!hidden"
          >
            <div className="m-[33px]">
              {searchInstitutionName
                ? `Are you looking for ${searchInstitutionName}?`
                : `You are about to leave Plaid.`}
              <br />
              <br />
              Accessing this institution will require leaving Plaid, are you ready?
            </div>
          </Popup>
        )}
      </div>

      {flinksInstitutionId && service === "plaid" && (
        <Popup
          onCancel={handlePopupCancel}
          onConfirm={handlePopupConfirm}
          onClose={handlePopupCancel}
          classNamesContainer="!hidden sm:!flex"
        >
          <div className="m-[33px]">
            {searchInstitutionName
              ? `Are you looking for ${searchInstitutionName}?`
              : `You are about to leave Plaid.`}
            <br />
            <br />
            Accessing this institution will require leaving Plaid, are you ready?
          </div>
        </Popup>
      )}

      <div className={`caption-medium text-left mt-[32px] sm:mt-[24px] ${openMobileBankingDialog ? "hidden" : ""}`}>
        <div className="mb-[4px]">iCash's commitment to security:</div>
        <div className="font-600 mb-[4px]">- It's anonymous</div>
        <div className="mb-[4px]">
          We use the same encryption system as banks and we do not sell any of your personal information to third parties.
        </div>
        <div className="font-600 mb-[4px]">- 256 bit encryption</div>
        <div>Your data is securely encrypted using bank-level 256 bit encryption</div>

        <Button
          size="large"
          className={
            "flex-1 sm:py-[13px] sm:text-[16px] hidden sm:block sm:mt-8 sm:w-full" +
            (openMobileBankingDialog ? "sm:!hidden" : "")
          }
          appearance="primary"
          onClick={() => setOpenMobileBankingDialog(true)}
          loading={false}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
