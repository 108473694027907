import type { NavigateFunction } from "react-router-dom"

export const CURRENT_STEP_KEY = "icash:current-step"

export const InstantLoanRoutes = [
  { path: "" },
  { path: "mobile-verification", state: { title: "Mobile phone validation" } },
  { path: "pin-verification", state: { title: "Verify your mobile phone" } },
  {
    path: "personal-details",
    state: { title: "Personal details", step: 1 },
  },
  {
    path: "residential-details",
    state: { title: "Residential details", step: 2 },
  },
  {
    path: "employment-details",
    state: { title: "Employment details", step: 3 },
  },
  {
    path: "income-details",
    state: { title: "Income details", step: 4 },
  },
  {
    path: "financial-details",
    state: { title: "Financial details", step: 5 },
  },
  //No step 6 (was references details but it has been removed.)
  { path: "banking-details", state: { title: "Validate your bank", step: 7 } },
  { path: "loan-preapproved", state: { title: "Loan pre-approved" } },
]

export const goToRouteStep = (navigate: NavigateFunction, step: number) => {
  const route = InstantLoanRoutes.find((tmp) => tmp.state?.step === step)
  if (route) {
    navigate(`../${route.path}`, {
      state: route.state,
    })
  }
}

export const goToRouteIndex = (navigate: NavigateFunction, index: number) => {
  navigate(`../${InstantLoanRoutes[index].path}`, {
    state: InstantLoanRoutes[index].state,
  })
}

export const goToRoutePath = (navigate: NavigateFunction, path: string) => {
  const index = InstantLoanRoutes.findIndex((tmp) => tmp.path === path)
  navigate(`../${InstantLoanRoutes[index].path}`, {
    state: InstantLoanRoutes[index].state,
  })
}

export const goToMobileVerification = (navigate: NavigateFunction) => {
  const route = InstantLoanRoutes.find((tmp) => tmp.path === "mobile-verification")
  if (route) {
    navigate(`../${route.path}`, {
      state: route.state,
    })
  }
}

export const goToPersonalDetails = (navigate: NavigateFunction) => {
  const route = InstantLoanRoutes.find((tmp) => tmp.path === "personal-details")
  if (route) {
    navigate(`../${route.path}`, {
      state: route.state,
    })
  }
}

export const goToBankingDetails = (navigate: NavigateFunction) => {
  const route = InstantLoanRoutes.find((tmp) => tmp.path === "banking-details")
  if (route) {
    navigate(`../${route.path}`, {
      state: route.state,
    })
  }
}

export const goToNext = (
  navigate: NavigateFunction,
  path: (typeof InstantLoanRoutes)[number]["path"]
) => {
  const currentIndex = InstantLoanRoutes.findIndex((route) => route.path === path)

  if (currentIndex === InstantLoanRoutes.length - 1) goToRouteIndex(navigate, 0)
  else goToRouteIndex(navigate, currentIndex + 1)
}

export const canGoToPrevious = (path: (typeof InstantLoanRoutes)[number]["path"]) => {
  const currentIndex = InstantLoanRoutes.findIndex((route) => route.path === path)
  if (currentIndex <= 3 || currentIndex >= 10) return false
  return true
}

export const goToPrevious = (
  navigate: NavigateFunction,
  path: (typeof InstantLoanRoutes)[number]["path"]
) => {
  const currentIndex = InstantLoanRoutes.findIndex((route) => route.path === path)

  if (currentIndex === 0) goToRouteIndex(navigate, 0)
  else goToRouteIndex(navigate, currentIndex - 1)
}

export const goToPastStep = (
  navigate: NavigateFunction,
  path: string,
  route: { state?: { step?: number }; path: string }
) => {
  const currentRoute = InstantLoanRoutes.find((tmp) => tmp.path === path)

  if (currentRoute) {
    const routeIndexSelected = InstantLoanRoutes.findIndex((tmp) => tmp.path === route.path)

    if (routeIndexSelected === -1) {
      return
    }

    const currentStep = currentRoute.state?.step ?? 0
    const selectedStep = route.state?.step ?? 0
    if (selectedStep < currentStep) {
      goToRouteIndex(navigate, routeIndexSelected)
    }
  }
}
