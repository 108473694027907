import { InstantLoanStateType } from "../../../Reducers/types"

export const personalDetailsParser = (
  state: InstantLoanStateType["personalDetails"]
): Partial<Record<keyof InstantLoanStateType["personalDetails"], any>> => ({
  ...state,
  gender: parseInt(state.gender || ""),
  sin: parseInt(state.sin ? state.sin.replaceAll(" ", "") : ""),
  dateOfBirth: state.dateOfBirth?.toLocaleDateString("fr-CA") || null, // fall back to null to indicate date needs validation after submit
  levelOfEducation: parseInt(state.levelOfEducation || ""),
  maritalStatus: parseInt(state.maritalStatus || ""),
  numberOfDependants: parseInt(state.numberOfDependants || ""),
  homePhone: state.homePhone?.replaceAll("-", ""),
  otherPhone: state.otherPhone?.replaceAll("-", ""),
})
