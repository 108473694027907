import { useEffect, useRef } from "react"
import { parseFlinksURLString } from "./helpers"
import preloaderImg from "../../../images/preloader.svg"
import type { FlinksDataStateType } from "../../../Helpers/Reducers/FlinksData/types"
import { postMessageSender } from "../../../Helpers/PostMessage"

type FlinksProps = {
  src: string
  institutionId?: string
  clientEmail: string
  clientId: number
  onRedirect: () => void
  onSelect: () => void
  onSuccess: (data: FlinksDataStateType) => void
  onStep: (data: FlinksDataStateType) => Promise<boolean>;
  onExit: () => void
  open: boolean
}

export function Flinks(props: FlinksProps) {
  const flinksRef = useRef<HTMLIFrameElement | null>(null)
  const flinksData = useRef<FlinksDataStateType>({ steps: [] })

  useEffect(() => {
    const messageEventListener = async (e: MessageEvent) => {
      let message = typeof e.data === "string" ? JSON.parse(e.data) : e.data

      let d = new Date()
      let month = d.getMonth() + 1
      let day = d.getDate()
      let hours = d.getHours()
      let minutes = d.getMinutes()
      let seconds = d.getSeconds()
      let now =
        d.getFullYear() +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day +
        "T" +
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds +
        "Z"

      if (message.step && message.institution && message.step === "COMPONENT_LOAD_CREDENTIAL") {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_banking_bank_selected",
              properties: {
                bankInstitution: message.institution.toString(),
              },
            },
          },
        })
      }

      if (message.step && message.institution) {
        flinksData.current = {
          ...flinksData.current,
          steps: [
            ...flinksData.current.steps,
            { date: now, institution: message.institution.toString(), step: message.step },
          ],
        }
      }

      let success = true;
      if (message.requestId) {
        const steps = [...flinksData.current.steps]
        const institutionStep = steps.find(step => step.institution !== undefined)?.institution;
        const institution = message?.institution?.toString() ?? institutionStep;
        if (institution) {
          success = await props.onStep(
            {
              steps: steps,
              institution: institution,
              loginId: message?.loginId,
              requestId: message?.requestId,
              flinksCode: message?.flinksCode,
            })
          } else {
            console.warn('[instituion is empty] Message from flinks: ', message);
            success = false;
          }
          if (success) {
            flinksData.current.steps = [];
          }
        }

      if (message?.requestId && message?.loginId && !message?.step && success) {
        flinksData.current = {
          ...flinksData.current,
          institution: message?.institution?.toString(),
          loginId: message?.loginId,
          requestId: message?.requestId,
          flinksCode: message?.flinksCode,
        }
        props.onSuccess(flinksData.current)
      }
    }

    window.addEventListener("message", messageEventListener)

    return () => {
      window.removeEventListener("message", messageEventListener)
    }
  }, [])

  return (
    <div
      className="sm:absolute flex justify-center items-center left-0 right-0 z-10 sm:-mx-[16.5px] sm:-mt-10 h-[650px] sm:h-[900px] max-w-[622px]"
      style={{ maxHeight: "calc(100vh - 55px)" }}
    >
      <img src={preloaderImg} className="absolute z-0 w-6 h-6 opacity-50" />
      <iframe
        ref={flinksRef}
        id="flinks-connect"
        className="relative z-1 w-full h-full"
        src={parseFlinksURLString(
          props.src,
          {
            contactId: props.clientId,
            email: props.clientEmail,
            date: new Date().toLocaleDateString("fr-CA"),
          },
          props.institutionId
        )}
      />
    </div>
  )
}
