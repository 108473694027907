import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Button,
  DateInput,
  InputGroup,
  PhoneInput,
  SelectInput,
  TextInput,
} from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { listParser } from "../../../Helpers/Parsers/List"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { InstantLoanStateType } from "../../../Helpers/Reducers/types"
import { personalDetailsParser } from "../../../Helpers/Parsers/Steps/PersonalDetails"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { getStepRequest, postStepRequest } from "../../../Helpers/Requests"
import { goToNext, goToRouteIndex } from "../../../Helpers/Routes"
import { StepsFormContainer } from "../../Utilities/StepsFormContainer"
import { personalDetailsValidators } from "../../../Helpers/Validators/Steps/PersonalDetails"
import { validateValues } from "../../../Helpers/Validators"

import { toast } from "react-toastify"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function PersonalDetails() {
  let [isSubmitting, setIsSubmitting] = useState(false)
  let [isLoading, setIsLoading] = useState(true)
  let [hasHomePhone, setHasHomePhone] = useState<string | number>("Yes")
  const [lists, setLists] = useState({
    contactMaritalStatus: [],
    contactDependents: [],
    contactGender: [],
    contactLevelEducation: [],
  })

  const navigate = useNavigate()

  const {
    state: { personalDetails, loanParameters, isApp },
    dispatch,
  } = useContext(InstantLoanContext)

  // details cleanup is needed for submit, progress bar etc
  const cleanupDetails = (details: typeof personalDetails) => {
    if (hasHomePhone === "Yes") {
      details.otherPhone = undefined
      details.otherFirstName = undefined
      details.otherLastName = undefined
      details.otherRelationship = undefined
      details.homePhone ||= ""
    } else {
      details.homePhone = undefined
      details.otherPhone ||= ""
      details.otherFirstName ||= ""
      details.otherLastName ||= ""
      details.otherRelationship ||= ""
    }
    return details
  }

  const {
    state: { token },
  } = useContext(RequestsContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  // current form field errors
  const [errors, setErrors] = useState<Record<string, string>>({})

  const handleValueChange =
    <T extends string | number | Date>(key: keyof typeof personalDetails) =>
    (value?: T) => {
      setErrors({})
      dispatch({
        step: "personalDetails",
        payload: cleanupDetails({ ...personalDetails, [key]: value }),
      })
    }

  const handleSubmit = () => {
    
    let personalDetailsValues: InstantLoanStateType["personalDetails"] = cleanupDetails(
      personalDetailsParser(personalDetails)
    )

    // validate personal details
    let validationErrors = validateValues(personalDetailsValues, personalDetailsValidators as {})
    if (validationErrors) {
      // scroll to first document error
      setTimeout(
        () => document.querySelector(".global--input-error")?.scrollIntoView({ behavior: "smooth", block: "center" })
      )
      return setErrors(validationErrors)
    }
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_personal_information_submitted",
        },
      },
    })

    setIsSubmitting(true)
    postStepRequest(1, personalDetailsValues, token)
      .then(() => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_personal_information_completed",
            },
          },
        })
        goToNext(navigate, path)
      })
      .catch((e) => {
        toast(e.message || e, { type: "error" })
        setIsSubmitting(false)
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getStepRequest(1, token)
      .then(({ data, list }) => {
        // initialize options for selects
        for (let listName in list) list[listName] = listParser(list[listName])
        setLists(list)

        setHasHomePhone((hasHomePhone = !!data.homePhone ? "Yes" : "No"))

        dispatch({
          step: "personalDetails",
          payload: cleanupDetails({
            ...data,
            sin: data.sin.length > 1 ? data.sin : data.gender !== "0" ? "000000000" : "",
            dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth + "T00:00:00") : undefined,
          }),
        })
      })
      .then(() => setIsLoading(false))
      .catch((e) => {
        if (e.code === 400) goToRouteIndex(navigate, 1)
        toast(e.message || e, { type: "error" })
        setIsLoading(false)
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
  }, [])

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_personal_information_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={isLoading || !token}>
      <StepsFormContainer>
        <div className="grid grid-cols-2 gap-x-[22px] mb-[22px] items-start sm:items-stretch sm:block">
          <TextInput
            className="sm:mt-[11px] sm:mb-[22px]"
            label="First name"
            value={personalDetails.firstName}
            error={errors.firstName}
            onChange={handleValueChange("firstName")}
            id="test-firstname-step1"
            disabled={isSubmitting}
          />
          <TextInput
            optional
            className="sm:mt-[30px] sm:mb-[22px]"
            label="Middle name"
            value={personalDetails.middleName}
            error={errors.middleName}
            onChange={handleValueChange("middleName")}
            id="test-middlename-step1"
            disabled={isSubmitting}
          />
          <TextInput
            className="mt-8 sm:mt-[11px] sm:mb-[22px]"
            label="Last name"
            value={personalDetails.lastName}
            error={errors.lastName}
            onChange={handleValueChange("lastName")}
            id="test-lastname-step1"
            disabled={isSubmitting}
          />
          <SelectInput
            native={isApp ? false : undefined}
            className="sm:mt-[11px] sm:mb-[22px]"
            label="Title"
            value={personalDetails.gender}
            error={errors.gender}
            onChange={handleValueChange("gender")}
            options={lists.contactGender}
            id="test-gender-step1"
            disabled={isSubmitting}
          />
          <div data-private>
            <TextInput
              className="mt-8 sm:mt-[11px] sm:mb-[22px]"
              label="SIN"
              value={personalDetails.sin}
              error={errors.sin}
              inputMode="numeric"
              onChange={handleValueChange("sin")}
              id="test-sin-step1"
              disabled={isSubmitting}
            />
          </div>
          <DateInput
            className="sm:mt-[11px] sm:mb-[22px]"
            label="Date of birth (yyyy-mm-dd)"
            placeHolder="Select"
            value={personalDetails.dateOfBirth ? personalDetails.dateOfBirth : undefined}
            error={errors.dateOfBirth}
            maxDate={personalDetails.dateOfBirth ? new Date() : undefined}
            onChange={handleValueChange("dateOfBirth")}
            id="test-birthdate-step1"
            disabled={isSubmitting}
          />
          <SelectInput
            native={isApp ? false : undefined}
            className="sm:mt-[11px] sm:mb-[22px]"
            label="Marital Status"
            value={personalDetails.maritalStatus}
            error={errors.maritalStatus}
            onChange={handleValueChange("maritalStatus")}
            options={lists.contactMaritalStatus}
            id="test-marital-step1"
            disabled={isSubmitting}
          />
          <SelectInput
            native={isApp ? false : undefined}
            className="sm:mt-[11px] sm:mb-[22px]"
            label="Number of dependants"
            value={personalDetails.numberOfDependants}
            error={errors.numberOfDependants}
            onChange={handleValueChange("numberOfDependants")}
            options={lists.contactDependents}
            id="test-dependants-step1"
            disabled={isSubmitting}
          />
          <SelectInput
            native={isApp ? false : undefined}
            className="sm:mt-[11px] sm:mb-[22px]"
            label="Level of education"
            value={personalDetails.levelOfEducation}
            error={errors.levelOfEducation}
            onChange={handleValueChange("levelOfEducation")}
            options={lists.contactLevelEducation}
            id="test-education-step1"
            disabled={isSubmitting}
          />
          <div />
          <InputGroup className="col-span-2 grid grid-cols-2 gap-x-[22px] pt-4 mb-4 items-start sm:flex sm:flex-col sm:items-stretch">
            <SelectInput
              native={isApp ? false : undefined}
              className="sm:mt-[11px] sm:mb-[22px] sm:w-full whitespace-nowrap"
              label="Additional phone number"
              value={hasHomePhone}
              disabled={isSubmitting}
              onChange={(value: any) => (
                setErrors({}),
                setHasHomePhone((hasHomePhone = value || "No")),
                dispatch({
                  step: "personalDetails",
                  payload: cleanupDetails({ ...personalDetails }),
                })
              )}
              options={[
                { value: "Yes", label: "I have one" },
                { value: "No", label: "I don't have one" },
              ]}
              id="test-have-phone-select"
            />
            {hasHomePhone === "Yes" ? (
              <PhoneInput
                className="mt-8 sm:mt-[11px] sm:mb-[22px] sm:w-full"
                label="Other/home phone"
                value={personalDetails.homePhone}
                error={errors.homePhone}
                onChange={handleValueChange("homePhone")}
                id="test-home-phone"
                disabled={isSubmitting}
              />
            ) : (
              <div className="col-span-2">
                <p className="caption-light">Enter the contact details of a friend or relative</p>
              <div className="grid grid-cols-2 gap-x-[22px] my-[22px] items-start sm:flex sm:flex-col sm:items-stretch">
                <PhoneInput
                  className="sm:mt-[11px] sm:mb-[22px]"
                  label="Phone number"
                  value={personalDetails.otherPhone}
                  error={errors.otherPhone}
                  onChange={handleValueChange("otherPhone")}
                  disabled={isSubmitting}
                />
                <TextInput
                  className="sm:mt-[11px] sm:mb-[22px]"
                  label="Relation to you"
                  value={personalDetails.otherRelationship}
                  error={errors.otherRelationship}
                  onChange={handleValueChange("otherRelationship")}
                  disabled={isSubmitting}
                />
                <TextInput
                  className="sm:mt-[11px] sm:mb-[22px]"
                  label="First name"
                  value={personalDetails.otherFirstName}
                  error={errors.otherFirstName}
                  onChange={handleValueChange("otherFirstName")}
                  disabled={isSubmitting}
                />
                <TextInput
                  className="sm:mt-[11px] sm:mb-[22px]"
                  label="Last name"
                  value={personalDetails.otherLastName}
                  error={errors.otherLastName}
                  onChange={handleValueChange("otherLastName")}
                  disabled={isSubmitting}
                />
              </div>
              </div>
            )}
          </InputGroup>
        </div>
        <div className="flex sm:space-x-0 flex-col">
          <p className="caption mb-[32px] sm:mb-[24px]">
            By clicking Continue, I confirm that the information provided is current and accurate.
          </p>
          <Button
            size="large"
            className="flex-1"
            appearance="primary"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!!Object.values(errors).join("") || isSubmitting}
          >
            Continue
          </Button>
        </div>
      </StepsFormContainer>
    </Loader>
  )
}

// note against component validators:
// * there's no way to trigger validation on form submit
// * validation logic is too scattered
// * validators are isolated, whereas we may need cross-dependent validation
// * no way to easily customize messages
