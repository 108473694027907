import { btoa } from "abab"

export const parseFlinksURLString = (
  src: string,
  tag: { email: string; contactId: number; date: string },
  institutionId?: string
) => {
  const base64TagContent = btoa(tag.contactId + "|" + tag.email + "|" + tag.date) ?? ""
  const base64Tag = btoa(base64TagContent + btoa("youwontgetmyemail") ?? "") ?? ""
  const isDemo = process.env.REACT_APP_FLINKS_DEMO === "TRUE" ? "demo=true&" : ""
  const backbutton = process.env.REACT_APP_FLINKS_DEMO === "TRUE" ? "backEnable=true" : "backEnable=false"
  if (institutionId && institutionId.length > 0) {
    return `${src}/Credential/${institutionId}?${isDemo}backgroundColor=ffffff&daysOfTransactions=Days365&foregroundColor1=2c2f3e&foregroundColor2=2c2f3e&termsUrl=https://icash.ca/terms-conditions&customerName=%20iCASH&fixedHeightEnable=false&${backbutton}&tag=${base64Tag}&stringify=true&windowContext=parent&consentEnable=false`
  }
  return `${src}?${isDemo}backgroundColor=ffffff&daysOfTransactions=Days365&foregroundColor1=2c2f3e&foregroundColor2=2c2f3e&termsUrl=https://icash.ca/terms-conditions&customerName=%20iCASH&fixedHeightEnable=false&backEnable=true&tag=${base64Tag}&stringify=true&windowContext=parent&consentEnable=false`
}
