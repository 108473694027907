type ResponseMessageDataType =
  | {
      event: "error"
      error: {
        message: string
        data?: any
        code?: number
      }
    }
  | {
      event: "finished"
    }
  | {
      event: "ready"
    }
  | {
      event: "display-website"
      payload: {
        url: string
      }
    }
  | {
      event: "repayment-options-side-panel"
      payload: {
        route: string
      }
    } // Display a side panel with the repayment options -> You must load a new iframe and send the token and the route : postMessage({ token: token, route: sidePanelRoute }, "*").
  | {
      event: "licence-information-side-panel"
      payload: {
        provinceCode?: string
      }
    } // Display the province license according to the provinceCode : (QC|ON|AB|BC...)
  | {
      event: "size"
      payload: {
        height: number
      }
    } /// Only avaible for large screen device on web. Do not check that for mobile phone.
  | {
      event: "marketing"
      payload:
        | {
            status: "screen"
            data: {
              categoryName: string
              pathName: string
              properties?: any
            }
          }
        | {
            status: "track"
            data: {
              event: string
              properties?: any
            }
          }
    }
  | {
      event: "return"
    }
  | {
      event: "marketing-group-updated",
      payload: {
        checked: boolean
      }
    }
  | {
      event: "loan-preapproved"
    }
  | {
      event: "contact-us"
    }

export const postMessageSender = (data: ResponseMessageDataType) => {
  try {
    const tmp = JSON.stringify(data)
    parent.postMessage(tmp, "*")
  } catch (error) {
    console.warn("postMessageSender:", error)
  }
}
