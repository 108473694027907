import {
  AlertSection,
  Button,
  CheckboxInput,
  DashboardSkeleton,
  DateInput,
} from "@brainfinance/icash-component-library"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import { ReviewType } from "../../../Helpers/Parsers/FastTrack/Review"
import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"
import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import {
  getStep1FastTrack,
  getStep2FastTrack,
  getUser,
  postStep1FastTrack,
  postStep2FastTrack,
} from "../../../Helpers/Requests"
import { goToBankingDetails, goToPersonalDetails, goToRouteStep } from "../../../Helpers/Routes"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function ReviewDetails() {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState<ReviewType>();
  const [lastPayDate, setLastPayDate] = React.useState<Date>();
  const [nextPayDate, setNextPayDate] = React.useState<Date>();
  const [error, setError] = React.useState<string>();
  const [dateError, setDateError] = React.useState<{lastPayDate: string|undefined; nextPayDate: string|undefined}>({lastPayDate:undefined, nextPayDate: undefined});

  const {
    state: { loanParameters, isApp, marketingConsentUpdated, marketingConsent },
    dispatch
  } = React.useContext(InstantLoanContext)
  const {
    state: { token },
  } = React.useContext(RequestsContext)

  const fetchInfo = async (tokenTmp: string) => {
    const info = await getStep1FastTrack(tokenTmp)

    if (info.error) {
      console.warn("step1 failed: ", info.error)
      goToPersonalDetails(navigate)
      return
    }

    const success = await postStep1FastTrack({ bankEtransfer: 1 }, token)

    if (!success) {
      console.warn("Failed to POST step1.")
      goToPersonalDetails(navigate)
      return
    }

    const dataStep2 = await getStep2FastTrack(tokenTmp)

    if (dataStep2.error) {
      console.warn("Failed to GET step2.")
      goToPersonalDetails(navigate)
      return
    }

    if (info.data && dataStep2.data) {
      setLastPayDate(dataStep2.data.lastPayDate ? new Date(dataStep2.data.lastPayDate + "T00:00:00") : undefined)
      setNextPayDate(dataStep2.data.nextPayDate ? new Date(dataStep2.data.nextPayDate + "T00:00:00") : undefined)
      setInfo(info.data)
    }
  }

  const onClickUpdateInformation = () => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_fast_track_update_information_clicked",
        },
      },
    })

    goToPersonalDetails(navigate)
  }

  const formatDateString = (date?: Date) => {
    if (date) {
      const yearNb = date.getFullYear()
      const dateNb = date.getDate()
      const monthNb = date.getMonth() + 1
      return yearNb + "-" + (monthNb >= 10 ? "" : "0") + monthNb + "-" + dateNb
    }
    return undefined
  }

  const onClickToContinue = async () => {
    const data: { lastPayDate: string | undefined; nextPayDate: string | undefined } = {
      lastPayDate: undefined,
      nextPayDate: undefined,
    }

    if (formatDateString(lastPayDate) === undefined) {
      data.lastPayDate = "Required"
    }
    if (formatDateString(nextPayDate) === undefined) {
      data.nextPayDate = "Required"
    }

    setDateError(data)

    if (data.lastPayDate !== undefined || data.nextPayDate !== undefined) {
      document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      return
    }

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_fast_track_review_submitted",
        },
      },
    })

    if (token) {
      
      const data = await postStep2FastTrack(
        {
          lastPayDate: formatDateString(lastPayDate),
          consent: true,
          nextPayDate: formatDateString(nextPayDate),
        },
        token
      )
      if (data.error) {
        setError(data.error.message)
      } else {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_fast_track_review_completed",
            },
          },
        })

        const userInfo = await getUser(token);

        if (userInfo.error) {
          console.warn("Failed to get user info.")
          goToPersonalDetails(navigate)
          return;
        }

        goToRouteStep(
          navigate,
          userInfo.result.applicationStep === 7 || userInfo.result.applicationStep === 6
            ? userInfo.result.applicationStep === 6 ? 5 : userInfo.result.applicationStep ///We removed the step 6 (reference details view) so if step 6 then we go to financial step (5)
            : userInfo.result.applicationStep + 1
        )

        // goToBankingDetails(navigate)
      }
    }
  }

  React.useEffect(() => {
    if (token) {
      fetchInfo(token)
    }
  }, [token])

  const nowWithOneDayMore = React.useMemo(() => {
    const now = new Date()
    now.setDate(now.getDate() + 1)
    return now
  }, [])

  React.useEffect(() => {
    setDateError({ lastPayDate: undefined, nextPayDate: undefined })
  }, [nextPayDate, lastPayDate])

  React.useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_fast_track_review_loaded",
        },
      },
    })
  }, [])

  if (!info) {
    return <DashboardSkeleton />
  }

  return (
    <div className="w-[529px] sm:w-full">
      <div id="content" className="">
        <div className="flex flex-1 w-full flex-col border rounded-3xl border-[#F5F5F5] p-[20px]  mt-[0px]" id='test-fastrack-card-details'>
          <h3 className="subtitle-caption col-span-2">Income details</h3>
          <p className="caption-light !my-[32px]">
            {info.salaryDetails?.netPaycheck?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
            {info.salaryDetails?.paycheckFrequency ?? ""}
          </p>
          <div className="flex flex-1 flex-row w-full sm:flex-col">
            <DateInput
              error={dateError.lastPayDate}
              maxDate={nowWithOneDayMore}
              className="w-1/2 mr-[4px] sm:w-auto sm:mr-0"
              label="Last pay date"
              value={lastPayDate}
              onChange={setLastPayDate}
            />
            <DateInput
              error={dateError.nextPayDate}
              minDate={nowWithOneDayMore}
              className="w-1/2 ml-[4px] sm:w-auto sm:mr-0"
              label="Next pay date"
              value={nextPayDate}
              onChange={setNextPayDate}
            />
          </div>
        </div>
        <div className="flex flex-1 w-full flex-col border rounded-3xl border-[#F5F5F5] p-[20px]  mt-[20px]" id='test-fastrack-card-address'>
          <h3 className="subtitle-caption col-span-2 mb-[10px]">Address</h3>
          <div className="flex flex-1 flex-col">
            <div className="w-full">
              <p className="caption-light !mb-[10px]">
                {info.addressDetails?.civicNumber} {info.addressDetails?.street ?? ""}
                {info.addressDetails?.aptNumber !== undefined
                  ? " apt " + info.addressDetails?.aptNumber
                  : ""}
              </p>
              <p className="caption-light !mb-[10px]">
                {info.addressDetails?.city ?? ""}
                {info.addressDetails?.stateShortCode
                  ? `, ${info.addressDetails?.stateShortCode}`
                  : ""}
                {info.addressDetails?.postalCode ? `, ${info.addressDetails?.postalCode}` : ""}
              </p>
              <p className="caption-light !mb-[0px]">Canada</p>
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full flex-col border rounded-3xl border-[#F5F5F5] p-[20px] mt-[20px]" id='test-fastrack-card-employment'>
          <h3 className="subtitle-caption col-span-2 mb-[10px]">Employment details</h3>
          <div className="flex flex-1 flex-row">
            <div className="w-full">
              <p className="caption-light !mb-[0px]">
                {info.employmentDetails?.employmentStatus}{" "}
                {info.employmentDetails?.employmentName
                  ? `(${info.employmentDetails?.employmentName})`
                  : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full flex-col border rounded-3xl border-[#F5F5F5] p-[20px] mt-[20px]" id='test-fastrack-card-sending'>
          <h3 className="subtitle-caption col-span-2 mb-[10px]">Sending funds</h3>
          <div className="flex flex-1 flex-row">
            <div className="w-full">
              <p className="caption-light mb-[10px]">E-transfers</p>
              <AlertSection className="ml-[12px]" status="warning">
                <p className="caption-light ml-[-12px] whitespace-pre-line !mb-[0px]">
                  Your funds will be sent via e-Transfer to the email used for your iCash account.
                  If you wish to change that, please contact us BEFORE you sign your contract. Also,
                  please be sure your email address is not set up for auto-deposit to a closed bank
                  account.
                </p>
              </AlertSection>
            </div>
          </div>
        </div>
        <div>
          <p className="caption text my-[32px]">
            By clicking Continue, I confirm that the information provided is current and accurate and I agree to Finabanx’s 
            {" "}<span 
              onClick={(e) => {
                e.stopPropagation();
                const url = "https://icash.ca/privacy-policy"
                if (!isApp) {
                  window.open(url, "_blank");
                } else {
                  postMessageSender(
                    {
                      event: "display-website",
                      payload: { url: url },
                    }
                  );
                }
              }} 
              className="text-primary cursor-pointer">
                Privacy Policy
            </span> and{" "}
            <span 
              onClick={(e) => {
                e.stopPropagation();
                const url = "https://icash.ca/terms-conditions"
                if (!isApp) {
                  window.open(url, "_blank");
                } else {
                  postMessageSender(
                    {
                      event: "display-website",
                      payload: { url: url },
                    }
                  );
                }
              }} 
              className="text-primary cursor-pointer">
                Terms of Use and Conditions
            </span>.
          </p>
        </div>
        <div className="w-full">
          <Button size="large"
            className="w-full"
            appearance="primary"
            onClick={onClickToContinue}
          >
            Continue
          </Button>
          <div className="flex flex-1 justify-center mt-[20px]">
            <p
              onClick={onClickUpdateInformation}
              className="caption-medium-no-colors text-primary mt-[8px] mb-[10px] cursor-pointer"
            >
              Update information
            </p>
          </div>
          {error && <p className="caption-medium-no-colors text-[#FF9494] mb-[10px]">{error}</p>}
        </div>
      </div>
    </div>
  )
}
