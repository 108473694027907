import {
  ProgressBar,
  ProgressBarNode,
  SimpleProgressBar,
} from "@brainfinance/icash-component-library"
import { useLocation, useNavigate } from "react-router-dom"
import { getStepFromLocationState, getTitleFromLocationState } from "./helpers"
import {
  canGoToPrevious,
  goToPastStep,
  goToPrevious,
  InstantLoanRoutes,
} from "../../../Helpers/Routes"
import "./style.css"

import chevronLeft from "../../../images/ic-chevron-left.svg"
import { MobileProgressBar } from "../ProgressBar"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function Header() {
  const { state, pathname, search } = useLocation()
  const navigate = useNavigate()

  const path = pathname.substring(1)
  const title = getTitleFromLocationState(state, pathname)
  const step = getStepFromLocationState(state, pathname)
  const routeSteps = InstantLoanRoutes.filter((route) => route.state?.step)

  return (
    <>
      <div className="flex sm:hidden">
        {step && title ? (
          <div className="flex flex-row flex-1">
            <div className="w-[500px] main-title-caption">{title}</div>
            <ProgressBar key={path} className="w-[100%] sm:flex-col sm:space-y-5 mr-5" title={""}>
              {routeSteps.map((route) => (
                <ProgressBarNode
                  key={route.path}
                  onClick={() => goToPastStep(navigate, path, route)}
                  active={route.path === path}
                  isCurrent={route.path === path}
                >
                  {route.state?.title}
                </ProgressBarNode>
              ))}
            </ProgressBar>
          </div>
        ) : (
          <h1 className="main-title-caption">{title || "Instant loan"}</h1>
        )}
      </div>
      <div className="hidden sm:block w-full">
        <div className="hidden sm:grid grid-cols-5 flex-row border-b-[0.5px] w-full border-[#D2D2D2]">
          <div>
            {canGoToPrevious(path) ? (
              <img
                src={chevronLeft}
                alt="Return"
                className="cursor-pointer col-span-1"
                onClick={() => goToPrevious(navigate, path)}
              />
            ) : !path || path.includes("personal-details") ? (
              <img
                src={chevronLeft}
                alt="Return"
                className="cursor-pointer col-span-1"
                onClick={() => postMessageSender({ event: "return" })}
              />
            ) : null}
          </div>
          <h1 className="text-[#13141A] w-full text-center text-[30px] font-[600] leading-[31px] tracking-[-1px] sm:text-[16px] sm:font-[500] sm:text-[#4E5972] sm:leading-[50px] sm:tracking-[-0.2px] col-span-3">
            {title || "Instant loan"}
          </h1>
        </div>
        <MobileProgressBar title={title} step={step} />
      </div>
    </>
  )
}
