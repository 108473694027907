import { useContext, useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Button, PhoneInput } from "@brainfinance/icash-component-library"
import { Loader } from "../../Utilities/Loader"

import { InstantLoanContext } from "../../../Helpers/Reducers/InstantLoan"

import { RequestsContext } from "../../../Helpers/Reducers/Requests"
import { postValidatePhone } from "../../../Helpers/Requests"
import { goToNext } from "../../../Helpers/Routes"
import { toast } from "react-toastify"
import { postMessageSender } from "../../../Helpers/PostMessage"

export function MobileVerification() {
  const [error, setError] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()

  const {
    state: { mobileVerification },
    dispatch,
  } = useContext(InstantLoanContext)

  const {
    state: { token },
  } = useContext(RequestsContext)

  const { pathname } = useLocation()
  const path = pathname.substring(1)

  const handleValueChange = (value?: string | number) => {
    let mobileNumber: string | undefined
    if (typeof value !== "string") mobileNumber === value?.toString()
    else mobileNumber = value

    setError(undefined)

    dispatch({ step: "mobileVerification", payload: { mobileNumber } })
  }

  const handleSubmit = () => {
    if (mobileVerification.mobileNumber && mobileVerification.mobileNumber.length < 12) {
      setError("Enter a valid 10 digit number")
      return
    }

    postMessageSender({
      event: "marketing",
      payload: {
        status: "track",
        data: {
          event: "app_phone_validation_submitted",
          properties: {
            phoneNumber: mobileVerification.mobileNumber,
          },
        },
      },
    })

    setIsSubmitting(true)
    postValidatePhone(mobileVerification, token)
      .then(() => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_phone_validation_completed",
              properties: {
                phoneNumber: mobileVerification.mobileNumber,
              },
            },
          },
        })
        goToNext(navigate, path)
      })
      .catch((e) => {
        postMessageSender({
          event: "marketing",
          payload: {
            status: "track",
            data: {
              event: "app_phone_validation_error",
              properties: {
                error: e.message ?? "",
              },
            },
          },
        })
        toast(e.message || e, { type: "error" })
        // scroll to top of document
        document.querySelector("#scroll-anchor")?.scrollIntoView({ behavior: "smooth", block: "start" })
      })
      .finally(() => setIsSubmitting(false))
  }

  useEffect(() => {
    postMessageSender({
      event: "marketing",
      payload: {
        status: "screen",
        data: {
          categoryName: "Application",
          pathName: "app_phone_validation_loaded",
        },
      },
    })
  }, [])

  return (
    <Loader loading={!token}>
      <div className="w-[529px] sm:w-full" onKeyDown={(e) => e.key === "Enter" && handleSubmit()}>
        <p className="caption-light mb-[32px]">
          We will send a text message with a verification code to this phone number.
        </p>
        <PhoneInput
          label="Mobile number"
          value={mobileVerification.mobileNumber}
          onChange={handleValueChange}
          error={error}
          className="!mb-[32px]"
          disabled={isSubmitting}
        />
        <Button
          size="large"
          appearance="primary"
          className="w-full"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting || !!error || !mobileVerification.mobileNumber}
        >
          Send verification code
        </Button>
      </div>
    </Loader>
  )
}
