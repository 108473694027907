import { InstantLoanStateType } from "../../../Reducers/types"
import { Validators } from "../../types"

export const incomeDetailsValidators: Validators<InstantLoanStateType["incomeDetails"]> = {
  paymentMethod(value, values) {
    if (!Number(value)) return "Required"
  },
  incomeAmount(value, values) {
    if (!Number(value)) return "Required"
  },
  additionalIncomeSource(value, values) {
    if (!Number(value)) return "Required"
  },
  additionalIncomeAmount(value, values) {
    if (!Number(value)) return "Required"
  },
  additionalIncomeFrequency(value, values) {
    if (!Number(value)) return "Required"
  },
  paymentSchedule(value, values) {
    if (!Number(value)) return "Required"
  },
  lastPayDate(value, values) {
    if (!value) return "Required"
  },
  nextPayDate(value, values) {
    if (!value) return "Required"
    if (+value <= +(values.lastPayDate || 0)) return "Next date must be after last date"
    if (+value <= +new Date()) return "Next date must be in the future"
  },
  joinBankAccount(value, values) {
    if (!Number(value)) return "Required"
  },
  bankEtransfer(value, values) {
    if (!Number(value)) return "Required"
  },
}

