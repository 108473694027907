import type { PlaidSearchOverride, PlaidSelectOverride } from "./types"

export function findSearchRedirect(
  plaidSearchOverride: PlaidSearchOverride[],
  query: string | null,
  callback: (flinksInstitutionId: string, searchInstitutionName?: string) => void
) {
  const override = plaidSearchOverride.find(
    (searchRedirectItem) =>
      searchRedirectItem.autoCompleteTrigger &&
      query?.toLowerCase().includes(searchRedirectItem.autoCompleteTrigger.toLowerCase())
  )
  if (override) callback(override.flinksInstitutionId, override.institutionName)
}

export function findSelectRedirect(
  plaidSelectOverride: PlaidSelectOverride[],
  id: string | null,
  callback: (flinksInstitutionId: string, searchInstitutionName?: string) => void,
  fallback: () => void
) {
  const override = plaidSelectOverride.find(
    (selectRedirectItem) => id === selectRedirectItem.plaidInstitutionId
  )
  if (override) callback(override.flinksInstitutionId)
  else fallback()
}
