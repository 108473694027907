import { InstantLoanRoutes } from "../../../Helpers/Routes"

export const getTitleFromLocationState = (state: any, pathname: string) => {
  if (typeof state === "object" && state && state.title) return state.title

  const routeInfo = InstantLoanRoutes.find((route) => route.path === pathname.substring(1))
  return routeInfo?.state?.title
}

export const getStepFromLocationState = (state: any, pathname: string) => {
  if (typeof state === "object" && state && state.step) return state.step

  const routeInfo = InstantLoanRoutes.find((route) => route.path === pathname.substring(1))
  return routeInfo?.state?.step
}
